import React, { useState } from "react";
// import { detectCardType } from "../Utils/getCardType";
import PreviousCard from "./PreviousCard";
import Skeleton from "react-loading-skeleton";
import CardElement from "./CardElement";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Translation from "../Language/Translation";
const stripePromise = loadStripe("pk_test_48vFCwDsXdk2iqSWBt1GKFU100EeR9Mon6");

const CreditDebitCard = ({
  cards,
  loading,
  setProcessPayment,
  price,
  guest,
  product,
  setCards,
  setTransactionError,
  paymentType,
  paymentIntentId,
}) => {
  // const [cardNumber, setCardNumber] = useState("");
  // const [cvv, setCvv] = useState({});
  // const [paymentType, setPaymentType] = useState("");
  const [active, setActive] = useState(null);

  // const formatCardNumber = (e) => {
  //   e.persist();
  //   e.preventDefault();
  //   let valueEntered = e.target.value;
  //   setCvv({});
  //   if (valueEntered.trim() !== valueEntered) {
  //     setCardNumber(valueEntered.trim());
  //     return;
  //   }
  //   let cardType = detectCardType(
  //     parseInt(valueEntered.trim().split(" ").join(""))
  //   );
  //   cardType && setPaymentType(cardType);
  //   valueEntered = valueEntered
  //     .split(" ")
  //     .join("")
  //     .split("")
  //     .map((ele, index) =>
  //       index === 3 || index === 7 || index === 11 ? ele + " " : ele
  //     )
  //     .join("");
  //   setCardNumber(valueEntered);
  // };
  return (
    <div className="cc-co-paym-fieldwrap">
      <div className="cc-co-form---credit w-form">
        {loading ? (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                marginBottom: "60px",
              }}
            >
              <Skeleton width={100} height={70} />
              <div style={{ width: "100%", paddingLeft: "20px" }}>
                <Skeleton count={1} />
                <div style={{ width: "90%" }}>
                  <Skeleton count={1} />
                </div>
              </div>
            </div>
          </div>
        ) : cards.length ? (
          cards.map((card, index) => (
            <PreviousCard
              paymentIntentId={paymentIntentId}
              {...card}
              active={active}
              setActive={setActive}
              // cvv={cvv[card.id]}
              key={index}
              // setCvv={setCvv}
              price={price}
              product={product}
              guest={guest}
              setCards={setCards}
              setProcessPayment={setProcessPayment}
              setTransactionError={setTransactionError}
              paymentType={paymentType}
            />
          ))
        ) : null}

        {cards.length ? (
          <label htmlFor="name-3" className="cc-co-form-label ccco-saved-sep">
            {Translation.t("OR_USE_NEW_CARD", "OR USE NEW CARD")}
          </label>
        ) : (
          <></>
        )}
        <Elements stripe={stripePromise}>
          <CardElement
            active={active}
            paymentIntentId={paymentIntentId}
            // cvv={cvv}
            setActive={setActive}
            price={price}
            product={product}
            guest={guest}
            setCards={setCards}
            setProcessPayment={setProcessPayment}
            setTransactionError={setTransactionError}
            paymentType={paymentType}
          />
        </Elements>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
  );
};

export default CreditDebitCard;
