import React from "react";
import { useHistory } from "react-router";
import "./index.css";
import config from "../config";
const masterCard = require("../assets/images/mastercard_circles_92px_2x.png");
const visa = require("../assets/images/full-color-800x450.jpg");

const PreviousCard = ({
  paymentType,
  active,
  setActive,
  card,
  id,
  guest,
  price,
  product,
  setProcessPayment,
  setTransactionError,
  paymentIntentId,
}) => {
  const history = useHistory();

  const handlePayment = async (id) => {
    setProcessPayment(true);
    try {
      if (paymentType === "subscription") {
        const res = await fetch(
          `${config.billingAPIUrl}/api/initialize/payment/stripe`,
          {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "admin-access-key":
                "admin-access-17a7c088-2d28-4fb3-a05c-39c1e92a1e8a",
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
              currency: "inr",
              payment_type: "recurring",
              user: {
                id: guest._id, // important
                details: {
                  email: guest.email, // important
                  source: id,
                  tax_exempt: "none", // important
                },
              },
              subscription: {
                // customer: "", set customer id
                items: [
                  {
                    price_data: {
                      currency: "inr",
                      product: product.id,
                      recurring: {
                        interval: "month",
                        interval_count: 1,
                      },
                      unit_amount: price * 100,
                    },
                    quantity: 1,
                  },
                ],
                default_payment_method: id,
              },
            }),
          }
        );
        const resp = await res.json();
        setProcessPayment(false);

        // mark payment as complete
        await fetch(
          `${config.billingAPIUrl}/api/update/paymentintent/${paymentIntentId}`,
          {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({ status: "SUCCESS" }),
          }
        );
        history.push(`/invoice/${resp.invoice.id}`);
      } else {
        const res = await fetch(
          `${config.billingAPIUrl}/api/initialize/payment/stripe`,
          {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "admin-access-key":
                "admin-access-17a7c088-2d28-4fb3-a05c-39c1e92a1e8a",
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
              currency: "inr",
              payment_type: "onetime",
              user: {
                id: guest._id, // important
                details: {
                  email: guest.email, // important
                  source: id,
                  tax_exempt: "none", // important
                },
              },
              invoice_items: [
                {
                  currency: "inr", // / importnat
                  amount: price * 100, // important
                  description: product.name, // service id //important
                },
              ],
              invoice: {
                auto_advance: true, // important
                collection_method: "send_invoice", // important
                days_until_due: 1, // important
                description: product.name,
              },
            }),
          }
        );
        const resp = await res.json();
        setProcessPayment(false);
        await fetch(
          `${config.billingAPIUrl}/api/update/paymentintent/${paymentIntentId}`,
          {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({ status: "SUCCESS" }),
          }
        );
        history.push(`/invoice/${resp.invoice.id}`);
      }
    } catch (err) {
      await fetch(
        `${config.billingAPIUrl}/api/update/paymentintent/${paymentIntentId}`,
        {
          method: "PUT", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({ status: "CANCELLED" }),
        }
      );
      setTransactionError(true);
    }
  };

  return (
    <div
      className={`ccco-saved-wrap ccco-mb0${
        active === id ? " card-active" : ""
      }`}
      style={{ cursor: "pointer" }}
      onClick={() => setActive(id)}
    >
      <div className="cco-saved-dwrap">
        <img
          src={card.brand === "visa" ? visa : masterCard}
          alt=""
          className="ccco-saved-visalogo"
        />
        <div className="ccco-saved-detailwrap">
          <label htmlFor="name-3" className="cc-co-form-label ccco-saved-title">
            **** **** **** {card.last4}
          </label>
          <div className="cc-co-sp-desc ccco-saved-exp">
            Exp {card.exp_month} / {card.exp_year}
          </div>
        </div>
      </div>

      <div className="cc-forminput-25">
        <button
          type="button"
          onClick={() => handlePayment(id)}
          className={`cc-primary--cta w-button`}
        >
          Pay
        </button>
      </div>

      {/* <div className="cc-forminput-25">
        <div className="cc-field-wrap mb0">
          <div className="cc-inputfield-wrap">
            <input
              type="password"
              className="cc-form-field w-input"
              maxLength={3}
              name="name-2"
              data-name="Name 2"
              placeholder="CVV"
              id="name-2"
              value={cvv || ""}
              onFocus={() => setActive(id)}
              onChange={(e) => {
                e.preventDefault();
                setCvv({ [id]: e.target.value });
              }}
            />
            <div className="tooltip-container tt-tr">
              <div className="tooltip-circle">
                <div className="tooltip-icon"></div>
              </div>
              <div className="tooltip-message-2">
                <div className="tooltip-message-txt">
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PreviousCard;
