import React, { useState } from "react";
import axios from "axios";

const applyOffer = async ({ cancelToken }) => {
  try {
  } catch (err) {}
};

const Offer = ({ offerDescription = "20% OFF on Purchasing 2 Year Plan" }) => {
  const [disable, setDisable] = useState(false);
  const source = axios.CancelToken.source();
  return (
    <div className="ccco-sum-offerwrap">
      <div className="ccco-sumoffer-innerwrap">
        <div className="ccco-sum-offer-pill">Offers</div>
        <div className="ccco-sum-offer-desc">{offerDescription}</div>
      </div>
      <div
        style={{ cursor: disable ? "not-allowed" : "pointer" }}
        disabled
        onClick={() => {
          if (!disable) {
            setDisable(true);
            applyOffer({ cancelToken: source.token })
              .then(() => {
                setDisable(false);
              })
              .catch((err) => {
                if (!axios.isCancel(err)) {
                  setDisable(false);
                }
              });
          }
        }}
        className="ccco-sp-offer-txt ccco-offer-cta"
      >
        Apply Offer
      </div>
    </div>
  );
};

export default Offer;
