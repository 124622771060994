import React from "react";
import Footer from "../Footer";

const PaymentSuccess = ({ success }) => {
  return (
    <div className="section">
      <div
        className="cc-checkout-container"
        style={{ width: "100vw", maxHeight: "100vh" }}
      >
        <div className="ccco-process-inner">
          <div className="ccco-process-inner-contianer">
            <div className="progress-ring-container">
              <div
                className={`ccco-progress-circle-success ${
                  success ? "load-complete" : ""
                }`}
              >
                <div
                  className="ccco-progress-ring-check check-draw"
                  style={{ display: success ? "block" : "none" }}
                />
              </div>
            </div>
            {/* <div className="ccco-process-rlink ccco-succes-tofggle">
              Toggle.{" "}
              <button type="button" className="ccco-success-toggle">
                Click here
              </a>
            </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default PaymentSuccess;
