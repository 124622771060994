import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Sidebar from "./Sidebar";
import CreditDebitCard from "./CreditDebitCard";
import Translation from "../Language/Translation";
import { useRouteMatch } from "react-router";
import PaymentSuccess from "../PaymentSuccess/PaymentSuccess";
import TransactionFailed from "../TransactionFailed";
import config from "../config"

const ssl = require("../assets/images/ssl.png");

const cardTypes = [
  { name: "Credit / debit card", code: "credit-debit-card" },
  { name: "PAYPAL CHECKOUT", code: "paypal-checkout" },
  { name: "GIFT CARDS", code: "gift-card" },
  { name: "DIGITAL WALLETS", code: "digital-wallet" },
];

const Payment = () => {
  const match = useRouteMatch();
  const [price, setPrice] = useState(0);
  const [selectedCard, setSelectedCard] = useState("credit-debit-card");
  const [paymentType, setPaymentType] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  // const [showGuestForm, setShowGuestForm] = useState(false);
  const [guest, setGuest] = useState({});
  const [useCredit, setUseCredit] = useState(false);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processingPayment, setProcessPayment] = useState(false);
  const [transError, setTransactionError] = useState(false);
  const [product, setProduct] = useState({
    id: "",
    name: "",
  });
  useEffect(() => {
    useCredit
      ? setPrice((p) => p - guest.credit || 0)
      : setPrice((p) => p + guest.credit || 0);
    // eslint-disable-next-line
  }, [useCredit]);

  useEffect(() => {
    setPaymentType(match.params.paymentType);
    setPaymentIntentId(match.params.paymentIntentId);
    if (match.params.productId) {
      fetch(`${config.billingAPIUrl}/api/get/product/${match.params.productId}`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, *same-origin, omit
        // body data type must match "Content-Type" header
      }).then((res) => {
        res.json().then((data) => {
          if (data) {
            setProduct(data);
            setPrice(parseInt(data.metadata.price));
          }
        });
      });
    }
  }, [match]);

  useEffect(() => {
    fetch(`${config.accountUrl}/accounts/get/account`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit
      // body data type must match "Content-Type" header
    })
      .then((response) => {
        response.json().then((data) => {
          const res = {
            data,
          };
          if (res.data && res.data[0])
            setGuest({
              username: res.data[0].displayName,
              email: res.data[0].email,
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // api
    //   .get("http://127.0.0.1:3001/auth/session")

    fetch(`${config.billingAPIUrl}/api/cards/saved/stripe`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit
      // body data type must match "Content-Type" header
    })
      .then((response) => {
        response.json().then((resp) => {
          const res = {
            data: resp,
          };
          if (res.data && Array.isArray(res.data)) setCards(res.data || []);
          setLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // api
    //   .get("${config.billingAPIUrl}/api/cards/saved/stripe")
  }, []);

  return !processingPayment ? (
    <div className="section">
      <div
        className="cc-checkout-container"
        style={{ width: "100vw", maxHeight: "100vh", borderRadius: "0px" }}
      >
        <div className="cc-checkout-upper">
          <div className="cc-checkout-left">
            <div className="cc-co-toptitlewrap">
              <div className="cc-co-title-wrap">
                <div className="cc-co-title">
                  {Translation.t(
                    "CHOOSE_PAYMENT_METHOD",
                    "Choose Payment Method"
                  )}
                </div>
              </div>
              <div className="cc-co-total-badge">
                <div className="cc-co-total-amount">₹{price}</div>
                <div className="cc-co-total-desc">
                  {Translation.t(
                    "INCLUSIVE_OF_ALL_TAXES.",
                    "Inclusive of All Taxes."
                  )}
                </div>
              </div>
            </div>
            <div className="cc-co-paym-container">
              <Sidebar
                cardTypes={cardTypes}
                setSelectedCard={setSelectedCard}
                selectedCard={selectedCard}
                // openGuestForm={() => setShowGuestForm(true)}
                useCredit={useCredit}
                guest={guest}
                setUseCredit={setUseCredit}
              />
              <div className="cc-co-paym-right ccco-saved-right">
                {selectedCard === "credit-debit-card" ? (
                  <CreditDebitCard
                    paymentType={paymentType}
                    setTransactionError={setTransactionError}
                    cards={cards}
                    setCards={setCards}
                    loading={loading}
                    setLoading={setLoading}
                    price={price}
                    product={product}
                    guest={guest}
                    setProcessPayment={setProcessPayment}
                    paymentIntentId={paymentIntentId}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <Footer footerIcons={[ssl]} />
          </div>
        </div>
        {/* <GuestForm
          open={showGuestForm}
          closeGuestForm={() => setShowGuestForm(false)}
          guest={guest}
          setGuest={setGuest}
        /> */}
      </div>
    </div>
  ) : transError ? (
    <TransactionFailed />
  ) : (
    <PaymentSuccess />
  );
};

export default Payment;
