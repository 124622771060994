import React from "react";

const ProductDetails = ({
  servicePrice = "900",
  couponDiscount = "80",
  tax = "10",
  total = "900",
  currency = "₹"
}) => {
  return (
    <div className="ccco-sum-pdetail-wrap">
      <div className="cc-co-sp-title cc-co-sp-title-small cc-co-mb10">
        Price Details
      </div>
      <div className="cc-pright-bd--inner ccco-w100">
        <div className="cc-title-gsm-2 cc-gsm-pb">Service Price</div>
        <div className="cc-title-gsm-2 cc-gsm-pb-price">
          {currency + servicePrice}
        </div>
      </div>
      <div className="cc-pright-bd--inner ccco-w100">
        <div className="cc-title-gsm-2 cc-gsm-pb">Coupon Discount</div>
        <div className="cc-title-gsm-2 cc-gsm-pb-price">
          {currency + couponDiscount}
        </div>
      </div>
      <div className="cc-pright-bd--inner ccco-w100">
        <div className="cc-title-gsm-2 cc-gsm-pb">Tax. Included</div>
        <div className="cc-title-gsm-2 cc-gsm-pb-price">{currency + tax}</div>
      </div>
      <div className="cc-pright-bd--inner ccco-sum-total ccco-sum-total-full">
        <div className="cc-title-gsm-2 cc-gsm-pb ccco-sum-ordertotal">
          Order Total
        </div>
        <div className="cc-title-gsm-2 cc-gsm-pb-price ccco-txtbold">
          {currency + total}
        </div>
      </div>
      <button type="button" className="cc-primary--cta ccco-mt20 w-button">
        Proceed to Payment
      </button>
    </div>
  );
};

export default ProductDetails;
