import React from "react";
import Translation from "../Language/Translation";
const visa = require("../assets/images/full-color-800x450.jpg");
const masterCard = require("../assets/images/mastercard_circles_92px_2x.png");
const americanExpress = require("../assets/images/AXP_BlueBoxLogo_EXTRALARGEscale_RGB_DIGITAL_1600x1600.png");
const [paypal, paypal500, paypal800, paypal1080] = [
  require("../assets/images/1280px-PayPal_logo.svg.png"),
  require("../assets/images/1280px-PayPal_logo.svg-p-500.png"),
  require("../assets/images/1280px-PayPal_logo.svg-p-800.png"),
  require("../assets/images/1280px-PayPal_logo.svg-p-1080.png")
];

const Footer = ({ footerIcons }) => {
  return (
    <div className="cc-co-bottom-wrap">
      <div className="cc-co-title-wrap">
        <div className="ccco-cards-footer">
          <img src={visa} alt="" className="ccco-footer-logo" />
          <img src={masterCard} alt="" className="ccco-footer-logo" />
          <img src={americanExpress} alt="" className="ccco-footer-logo" />
          <img
            src={paypal}
            srcSet={`${paypal}, ${paypal500}500w, ${paypal800} 800w, ${paypal1080} 1080w`}
            sizes="55px"
            alt=""
            className="ccco-footer-logo ccco-paypal-ico"
          />
          {footerIcons ? (
            footerIcons.map((icon) => (
              <img src={icon} key={icon} alt="" className="ccco-footer-logo" />
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="cc-co-securebadge">
          <div className="cc-co-secure-txt">
            <span className="cc-co-secure-ico"></span>
            {Translation.t("100%_SECURE", "100% Secure")}
          </div>
          <div className="cc-co-secure-txt">
            <span className="cc-co-secure-ico"></span>
            {Translation.t("256_BIT_SSL", "256 BIT SSL")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
