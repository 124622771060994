import React, { useState, useCallback } from "react";
import Coupon from "./Coupon";
import axios from "axios";
const MockAdapter = require("axios-mock-adapter");
const mock = new MockAdapter(axios, { delayResponse: 2000 });

mock.onGet("/coupons").reply(200, {
  products: [
    {
      productName: "Coot Analytics",
      bundleInfo: "Includes 4 Bundle Products",
      subscriptionId: "234CA20",
      price: "230",
      productImage: "",
      currency: "₹",
      unit: "Yr"
    }
  ]
});

const CouponBenifit = () => (
  <div className="ccco-su-pcard-desc--left ccco-c-s-applied">
    <div className="ccco-su-pcard-price-fv ccco-fla">
      <div className="cc-co-sp-title cc-co-sp-title-small">Discount</div>
    </div>
    <div className="ccco-su-pcard-price-fv ccco-fh">
      <div className="ccco-sp-price ccco-sp-price-small">₹20</div>
      <div className="ccco-su-pcard-pricewrap">
        <div className="ccco-sp-price ccco-sp-price-small-discount">
          (10% OFF)
        </div>
      </div>
    </div>
  </div>
);

const validateCoupon = async () => {
  try {
  } catch (err) {}
};

const CouponsContainer = ({ setTotalPrice }) => {
  // eslint-disable-next-line
  const [coupons, setCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disableApplyCoupon, setDisableApplyCoupon] = useState(false);
  const source = axios.CancelToken.source();
  const handleSubmit = (e) => {
    console.log("here");
    e.preventDefault();
    setDisableApplyCoupon(true);
    if (!couponCode) {
      setError(true);
      setSuccess(false);
      setDisableApplyCoupon(false);
    } else {
      setError(false);
      validateCoupon({ cancelToken: source.token })
        .then((coupon) => {
          setCoupons((couponList) => [...couponList, coupon]);
          setSuccess(true);
          setDisableApplyCoupon(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setError(true);
            setDisableApplyCoupon(false);
          }
        });
    }
  };

  const removeCoupon = useCallback((id) => {
    setCoupons((couponList) =>
      couponList.filter((coupon, index) => id !== index)
    );
  }, []);

  return (
    <div className="ccco-s-c-wrap">
      <div className="cc-co-sp-title cc-co-sp-title-small">
        Apply Coupon Code
      </div>
      <div className="ccco-sc-formwrap">
        <div className="ccco-sum-form w-form">
          <form
            id="email-form-2"
            name="email-form-2"
            data-name="Email Form 2"
            className="ccco-sum-form-inner"
            onSubmit={handleSubmit}
          >
            <div className="cc-inputfield-wrap">
              <input
                type="text"
                className="cc-co-form-field w-input"
                maxLength={256}
                name="name-2"
                data-name="Name 2"
                placeholder="Enter Code"
                id="name-2"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button
                style={{
                  cursor: disableApplyCoupon ? "not-allowed" : "pointer"
                }}
                className="ccco-sum-capply--cta w-button"
                type="submit"
              >
                Apply Coupon
              </button>
            </div>
          </form>
          <div
            className="w-form-done"
            style={{ display: success ? "block" : "none" }}
          >
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div
            className="w-form-fail"
            style={{ display: error ? "block" : "none" }}
          >
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
      <Coupon
        removeCoupon={removeCoupon}
        id={10}
        renderCouponBenifit={() => <CouponBenifit />}
      />
    </div>
  );
};

export default CouponsContainer;
