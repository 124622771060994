import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import Translation from "../Language/Translation";
import { useHistory } from "react-router";
import config from "../config";

const CardElement = ({
  active,
  setActive,
  price,
  product,
  guest,
  setCards,
  setProcessPayment,
  setTransactionError,
  paymentType,
  paymentIntentId,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardHolderName, setCardHolderName] = useState("");
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!active) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      try {
        const source = await stripe.createSource(
          elements.getElement(CardNumberElement),
          {
            type: "card",
            currency: "usd",
          }
        );
        setProcessPayment(true);
        const resp = await fetch(`${config.billingAPIUrl}/api/cards/add/stripe`, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({
            source: source.source.id,
          }),
        });
        const data = await resp.json();
        setCards((c) => [...c, data.card]);
        if (paymentType === "subscription") {
          const res = await fetch(
            `${config.billingAPIUrl}/api/initialize/payment/stripe`,
            {
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              mode: "cors", // no-cors, *cors, same-origin
              cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
                "admin-access-key":
                  "admin-access-17a7c088-2d28-4fb3-a05c-39c1e92a1e8a",
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify({
                currency: "inr",
                payment_type: "recurring",
                user: {
                  id: guest._id, // important
                  details: {
                    email: guest.email, // important
                    source: data.card.id,
                    tax_exempt: "none", // important
                  },
                },
                subscription: {
                  // customer: "", set customer id
                  items: [
                    {
                      price_data: {
                        currency: "inr",
                        product: product.id,
                        recurring: {
                          interval: "month",
                          interval_count: 1,
                        },
                        unit_amount: price * 100,
                      },
                      quantity: 1,
                    },
                  ],
                  default_payment_method: data.card.id,
                },
              }),
            }
          );
          const resp_data = await res.json();
          setProcessPayment(false);
          // mark payment as complete
          await fetch(
            `${config.billingAPIUrl}/api/update/paymentintent/${paymentIntentId}`,
            {
              method: "PUT", // *GET, POST, PUT, DELETE, etc.
              mode: "cors", // no-cors, *cors, same-origin
              cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify({ status: "SUCCESS" }),
            }
          );
          history.push(`/invoice/${resp_data.invoice.id}`);
        } else {
          const res = await fetch(
            `${config.billingAPIUrl}/api/initialize/payment/stripe`,
            {
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              mode: "cors", // no-cors, *cors, same-origin
              cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
                "admin-access-key":
                  "admin-access-17a7c088-2d28-4fb3-a05c-39c1e92a1e8a",
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify({
                currency: "inr",
                payment_type: "onetime",
                user: {
                  id: guest._id, // important
                  details: {
                    email: guest.email, // important
                    source: data.card.id,
                    tax_exempt: "none", // important
                  },
                },
                invoice_items: [
                  {
                    currency: "inr", // / importnat
                    amount: price * 100, // important
                    description: product.name, // service id //important
                  },
                ],
                invoice: {
                  auto_advance: true, // important
                  collection_method: "send_invoice", // important
                  days_until_due: 1, // important
                  description: product.name,
                },
              }),
            }
          );
          const resp_data = await res.json();
          setProcessPayment(false);
          // mark payment as complete
          await fetch(
            `${config.billingAPIUrl}/api/update/paymentintent/${paymentIntentId}`,
            {
              method: "PUT", // *GET, POST, PUT, DELETE, etc.
              mode: "cors", // no-cors, *cors, same-origin
              cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify({
                status: "SUCCESS",
                subscription_id: resp_data.subscription_id,
              }),
            }
          );
          history.push(`/invoice/${resp_data.invoice.id}`);
        }
      } catch (err) {
        await fetch(
          `${config.billingAPIUrl}/api/update/paymentintent/${paymentIntentId}`,
          {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({ status: "CANCELLED" }),
          }
        );
        setTransactionError(true);
      }
    }
  };

  return (
    <form
      id="email-form"
      name="email-form"
      data-name="Email Form"
      className="cc-co-forminner"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className="cc-co-fullinput-wrap">
        <div className="cc-co-field-wrap mb0">
          <label htmlFor="name-3" className="cc-co-form-label">
            {Translation.t("CARD_NUMBER", "CARD NUMBER")}
          </label>
          <div className="cc-inputfield-wrap">
            <CardNumberElement
              className="cc-co-form-field w-input"
              onFocus={() => setActive(null)}
            />
          </div>
        </div>
      </div>
      <div className="cc-co-form-inputwrap">
        <div className="cc-forminput-25">
          <div className="cc-field-wrap mb0">
            <label htmlFor="name-3" className="cc-form-label">
              {Translation.t("VALID_UPTO", "VAILD UPTO")}
            </label>
            <div className="cc-inputfield-wrap">
              <CardExpiryElement
                className="cc-form-field w-input"
                onFocus={() => setActive(null)}
              />
            </div>
          </div>
        </div>
        <div className="cc-forminput-25">
          <div className="cc-field-wrap mb0">
            <label htmlFor="name-3" className="cc-form-label">
              {Translation.t("CVV", "CVV")}
            </label>
            <div className="cc-inputfield-wrap">
              <CardCvcElement
                className="cc-form-field w-input"
                onFocus={() => setActive(null)}
              />
              <div className="tooltip-container tt-tr">
                <div className="tooltip-circle">
                  <div className="tooltip-icon"></div>
                </div>
                <div className="tooltip-message-2">
                  <div className="tooltip-message-txt">
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cc-forminput-50">
          <div className="cc-field-wrap mb0">
            <label htmlFor="name-3" className="cc-form-label">
              {Translation.t("NAME_ON_CARD", "NAME ON CARD")}
            </label>
            <div className="cc-inputfield-wrap">
              <input
                type="text"
                className="cc-form-field w-input"
                maxLength={256}
                name="name-2"
                data-name="Name 2"
                placeholder="As on Card"
                id="name-2"
                value={cardHolderName}
                onChange={(e) =>
                  setCardHolderName(e.target.value.toUpperCase())
                }
              />
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        className={`cc-primary--cta ${
          stripe //   ((active && cvv[active] && cvv[active].length === 3) ||
            ? //   (cardNumber.length === 19 &&
              //     cardCvv.length === 3 &&
              //     expiryDate &&
              //     cardHolderName))
              ""
            : "cco-cta-disabled"
        } w-button`}
      >
        {Translation.t("PROCEED_TO_PAYMENT", "Proceed to Payment")}
      </button>
    </form>
  );
};

export default CardElement;
