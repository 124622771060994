import React from "react";
import Translation from "../Language/Translation";

const Sidebar = ({
  cardTypes,
  setSelectedCard,
  selectedCard,
  // openGuestForm,
  useCredit,
  setUseCredit,

  guest,
}) => {
  return (
    <div className="cc-co-paym-tabcontainer">
      {guest.username ? (
        <div className="cc-co-creditbox">
          {guest.credit ? (
            <div className="ccco-su-pcard--desc ccco-fh ccco-scredit-wrap">
              <div className="ccco-su-pcard-desc--left ccco-fv ccco-w-auto">
                <div className="cc-co-sp-title cc-co-sp-title-small">
                  {Translation.t("AVAILABLE_CREDIT", "Available Credit")}
                </div>
                <div className="ccco-sp-price ccco-sp-price-md">
                  ₹{guest.credit}
                </div>
              </div>
              <div className="ccco-usecredit-form w-form">
                <form
                  id="email-form-3"
                  name="email-form-3"
                  data-name="Email Form 3"
                  className="ccco-usecredit-form--inner"
                >
                  <label className="w-checkbox ccco-usecredit-checkbox">
                    <div
                      className={`w-checkbox-input w-checkbox-input--inputType-custom ccco-usecredit-check ${
                        useCredit ? "w--redirected-checked" : ""
                      }`}
                    />
                    <input
                      type="checkbox"
                      id="checkbox-credit"
                      name="checkbox"
                      data-name="Checkbox"
                      style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                      checked={useCredit}
                      value={useCredit}
                      onChange={() => setUseCredit((val) => !val)}
                    />
                    <span className="ccco-usecredit-label ccco-credit-btn w-form-label">
                      {Translation.t("USE_CREDIT", "Use Credit")}
                    </span>
                  </label>
                </form>
              </div>
              {guest.username ? (
                <div className="ccco-su-pcard--desc ccco-fh ccco-scredit-wrap ccco-credit-user-info">
                  <div className="ccco-su-pcard-desc--left ccco-fv ccco-w-auto">
                    <div className="cc-co-sp-title cc-co-sp-title-small">
                      {guest.username ||
                        Translation.t(
                          "FILL_GUEST_DETAILS",
                          "Fill Guest Details"
                        )}
                    </div>
                    <div className="cc-co-sp-desc ccco-saved-exp">
                      {guest.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    style={{ cursor: "pointer" }}
                    // onClick={() => openGuestForm(true)}
                    className="ccco-profile-edit--btn cc-guestform-toggle cc-btn-trigger w-inline-block"
                  >
                    {/* <div className="ccco-profile-card-edit--ico"></div> */}
                  </button>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="ccco-su-pcard--desc ccco-fh ccco-scredit-wrap">
              <div className="ccco-su-pcard-desc--left ccco-fv ccco-w-auto">
                <div className="cc-co-sp-title cc-co-sp-title-small">
                  {guest.username ||
                    Translation.t("FILL_GUEST_DETAILS", "Fill Guest Details")}
                </div>
                <div className="cc-co-sp-desc ccco-saved-exp">
                  {guest.email}
                </div>
              </div>
              <button
                type="button"
                style={{ cursor: "pointer" }}
                // onClick={() => openGuestForm(true)}
                className="ccco-profile-edit--btn cc-guestform-toggle cc-btn-trigger w-inline-block"
              >
                {/* <div className="ccco-profile-card-edit--ico"></div> */}
              </button>
            </div>
          )}
        </div>
      ) : null}
      {cardTypes.map((card, index) => (
        <button
          type="button"
          key={index}
          style={{ cursor: "pointer" }}
          onClick={() => setSelectedCard(card.code)}
          className={`cc-co-paym-tab w-inline-block ${
            selectedCard === card.code ? "cc-co-paym-tab--active" : ""
          }`}
        >
          <div className="cc-co-tab-txt">{card.name}</div>
        </button>
      ))}
    </div>
  );
};

export default Sidebar;
