import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import Footer from "../Footer";
import Translation from "../Language/Translation";
import config from "../config";

const PaymentInvoice = () => {
  const match = useRouteMatch();

  const [invoice, setInvoice] = useState({
    status_transitions: {},
  });

  useEffect(() => {
    if (match.params.invoiceId) {
      fetch(
        `${config.billingAPIUrl}/api/invoice/get/${match.params.invoiceId}`,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "include",
        }
      ).then((res) => {
        res.json().then((data) => {
          if (data) setInvoice(data);
        });
      });
    }
  }, [match]);
  return (
    <div className="section" id="invoice">
      <div
        className="cc-checkout-container"
        style={{ width: "100vw", maxHeight: "100vh" }}
      >
        <div className="ccco-process-inner process-succes-details">
          <div className="cc-co-title-wrap ccco-title-success">
            <div className="ccco-pays-titlewrap">
              <div className="ccco-process-title ccco-process-stitle">
                <span className="ccco-paysuccess-ico"></span>
              </div>
              <div className="ccco-title-fv">
                <div className="ccco-process-title ccco-process-stitle">
                  {Translation.t("PAYMENT_SUCCESSFULL", "Payment Successful")}
                </div>
                <div className="cc-co-sp-title">
                  {Translation.t(
                    "PAYMENT_PROCESSED",
                    "Your Payment has been processed"
                  )}{" "}
                  !
                </div>
              </div>
            </div>
            <div className="ccco-pays-orderidwrap">
              <div className="cc-co-sp-desc">{("ORDER_ID", "Order ID")}</div>
              <div className="cc-co-total-amount ccco-color-black">
                #{invoice.number}
              </div>
            </div>
          </div>
          <div className="ccco-pays-details-container ccco-paysucces-container">
            <div className="cc-co-sp-title cc-co-sp-title-small cc-co-mb10">
              {Translation.t("TRANSLATION_DETAILS", "Transaction Details")}
            </div>
            <div className="ccco-pays-details-wrap">
              <div className="cc-pright-bd--inner">
                <div className="cc-title-gsm-2 cc-gsm-pb">
                  {Translation.t("AMOUNT", "Amount")}
                </div>
                <div className="cc-title-gsm-2 cc-gsm-pb-price">
                  ₹{invoice.amount_paid / 100}
                </div>
              </div>
              <div className="cc-pright-bd--inner">
                <div className="cc-title-gsm-2 cc-gsm-pb">
                  {Translation.t("ORDER_ID", "Order ID")}
                </div>
                <div className="cc-title-gsm-2 cc-gsm-pb-price">
                  #{invoice.number}
                </div>
              </div>
              <div className="cc-pright-bd--inner">
                <div className="cc-title-gsm-2 cc-gsm-pb">
                  {Translation.t("EMAIL", "E-Mail")}
                </div>
                <div className="cc-title-gsm-2 cc-gsm-pb-price">
                  {invoice.customer_email}
                </div>
              </div>
              {/* <div className="cc-pright-bd--inner">
                <div className="cc-title-gsm-2 cc-gsm-pb">
                  {Translation.t("PAYMENT_TYPE", "Payment Type")}
                </div>
                <div className="cc-title-gsm-2 cc-gsm-pb-price">
                  Credit Card
                </div>
              </div> */}
              <div className="cc-pright-bd--inner">
                <div className="cc-title-gsm-2 cc-gsm-pb">
                  {Translation.t("DATE_AND_TIME", "Date and Time")}
                </div>
                <div className="cc-title-gsm-2 cc-gsm-pb-price">
                  {new Date(
                    invoice.status_transitions.paid_at * 1000
                  ).toDateString()}{" "}
                  -{" "}
                  {new Date(
                    invoice.status_transitions.paid_at * 1000
                  ).toLocaleTimeString()}
                </div>
              </div>
              <div className="cc-pright-bd--inner">
                <div className="cc-title-gsm-2 cc-gsm-pb">
                  {Translation.t("TRANSACTION_ID", "Transaction ID")}
                </div>
                <div className="cc-title-gsm-2 cc-gsm-pb-price">
                  {invoice.id}
                </div>
              </div>
              <button
                type="button"
                onClick={() => window.print()}
                className="cc-primary--cta ccco-mt20 ccco-pays-invoicebtn w-button"
              >
                <span className="ccco-pays-invoice-ico"></span>
                {Translation.t(
                  "DOWNLOAD_PRINT_INVOICE",
                  "Download / Print Invoice"
                )}
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PaymentInvoice;
