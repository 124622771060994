import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./assets/css/normalize.css";
import "./assets/css/webflow.css";
import "./assets/css/coot-checkout.webflow.css";
import "./assets/css/custom.css";
import ProductSummary from "./ProductSummary/ProductSummary";
import ProcessingPayment from "./ProcessingPayment";
import TransactionFailed from "./TransactionFailed";
import PaymentSuccess from "./PaymentSuccess";
import PaymentInvoice from "./PaymentInvoice/indexs";
import Payment from "./Payment/index";

const Home = () => {
  return (
    <Router>
      {" "}
      <Switch>
        <Route
          path="/:paymentType/:productId/:paymentIntentId"
          exact
          render={() => <Payment />}
        />
        <Route
          path="/invoice/:invoiceId"
          exact
          render={() => <PaymentInvoice />}
        />
        <Route
          path="/"
          exact
          render={() => (
            <>
              <ProductSummary />
              <hr />
              <ProcessingPayment />
              <hr />
              <TransactionFailed />
              <hr />
              <PaymentSuccess />
              <hr />
              <PaymentInvoice />
              <hr />
              <Payment />
            </>
          )}
        />
      </Switch>
    </Router>
  );
}

export default Home;
