import React from "react";
const tempProductImage = require("../../assets/images/mastercard_circles_92px_2x.png");

const Product = ({
  renderOffer,
  productName,
  bundleInfo,
  subscriptionId,
  price,
  productImage,
  currency,
  unit
}) => {
  return (
    <>
      <div className="cc-co-summary-pwrap">
        <img
          src={productImage ? productImage : tempProductImage}
          alt=""
          className="ccco-sum-pro-img"
        />
        <div className="cc-co-sp-titlewrap">
          <div className="cc-co-sp-title">{productName}</div>
          <div className="cc-co-sp-bundle-desc">
            <div className="cc-co-budles-list">
              <button type="button" className="cc-co-bundle-listliink">
                {bundleInfo}
              </button>
            </div>
          </div>
          <div className="cc-co-sp-desc">Subscription ID #{subscriptionId}</div>
        </div>
        <div className="cc-co-sum-qty">
          <button type="button" className="ccco-qty-minus w-inline-block">
            <div className="ccco-qty-ico"></div>
          </button>
          <div className="cc-title-gsm-2 ccco-qty-number">01</div>
          <button type="button" className="ccco-qty-minus w-inline-block">
            <div className="ccco-qty-ico"></div>
          </button>
        </div>
        <div className="cc-co-sp-pricewrap">
          <div className="ccco-sp-price">{`${currency + price}/${unit}`}</div>
        </div>
      </div>
      {renderOffer()}
    </>
  );
};

export default Product;
