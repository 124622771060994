import React, { useState, useEffect } from "react";
import ProductsContainer from "./Products/ProductsContainer";
import CouponsContainer from "./Coupons/CouponsContainer";
import PriceDetails from "./PriceDetails/PriceDetails";
import CarouselProducts from "./CarouselProducts/CarouselProducts";
import ProductDetailsDialog from "./ProductDetailsDialog";
import axios from "axios";
const MockAdapter = require("axios-mock-adapter");
const mock = new MockAdapter(axios, { delayResponse: 2000 });

mock.onGet("/products").reply(200, {
  products: [
    // {
    //   productName: "Coot Analytics",
    //   bundleInfo: "Includes 4 Bundle Products",
    //   subscriptionId: "234CA20",
    //   price: "230",
    //   productImage: "",
    //   currency: "$",
    //   unit: "Yr"
    // }
  ]
});

const getProducts = async ({ cancelToken }) => {
  try {
    const response = await axios.get("/products", { cancelToken });
    if (response.data && Array.isArray(response.data.products)) {
      return response.data.products;
    }
    return [];
  } catch (err) {
    throw err;
  }
};

const ProductSummary = () => {
  const [displayDialog, setDisplayDialog] = useState(false);
  const [products, setProducts] = useState([]);
  const [refreshProducts, setRefreshProducts] = useState(false);
  // eslint-disable-next-line
  const [totalPrice, setTotalPrice] = useState(0);
  const source = axios.CancelToken.source();
  useEffect(() => {
    getProducts({ cancelToken: source.token })
      .then((productList) => {
        setProducts(productList);
        setTotalPrice(() =>
          productList.reduce((a, c) => a + parseInt(c.price), 0)
        );
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
        }
      });
    // eslint-disable-next-line
  }, [refreshProducts]);
  return (
    <div className="section">
      <div
        className="cc-checkout-container ccco-summary-container"
        style={{ width: "100vw", borderRadius: "0px" }}
      >
        <div
          className={`cc-checkout-upper ${displayDialog ? "opacity-40" : ""}`}
        >
          <div className="cc-checkout-left">
            <div className="cc-co-toptitlewrap">
              <div className="cc-co-title-wrap">
                <div className="cc-co-title">Product Summary</div>
              </div>
            </div>
            <div className="cc-co-paym-container ccco-summary-container">
              <div className="cc-co-summary-right">
                <ProductsContainer products={products} />

                <CarouselProducts
                  showDialog={() => setDisplayDialog(true)}
                  refreshProducts={() => setRefreshProducts((val) => !val)}
                />
              </div>

              <div className="ccco-summary-right">
                <CouponsContainer setTotalPrice={setTotalPrice} />
                <PriceDetails />
              </div>
            </div>
          </div>
        </div>

        <ProductDetailsDialog
          open={displayDialog}
          closeDialog={() => setDisplayDialog(false)}
        />
        {/* <div id="bundle-details" className="ccco-p-bundle-container">
          <div className="ccco-p-details-inner">
            <div className="ccco-sp-title-wrap">
              <div className="cc-co-title">Includes 4 Coot Products</div>
              <a
                href="#"
                className="ccco-sp-detail-close ccco-close cc-btn-trigger cc-co-bundle-listliink cc-budleclose w-inline-block"
              >
                <div className="cc-sp-close-ico cc-guestform-toggle ccco-close">
                  
                </div>
              </a>
            </div>
            <div className="ccco-p-bundles-container">
              <div className="cc-co-summary-pwrap cc-co-bundle-summary">
                <img
                  src="images/mastercard_circles_92px_2x.png"
                  alt=""
                  className="ccco-sum-pro-img"
                />
                <div className="cc-co-sp-titlewrap">
                  <div className="cc-co-sp-title">Coot Analytics Premium</div>
                  <div className="cc-co-sp-desc">Subscription ID #234CA20</div>
                </div>
                <div className="cc-co-sum-qty">
                  <button type="button" className="ccco-qty-minus w-inline-block">
                    <div className="ccco-qty-ico"></div>
                  </a>
                  <div className="cc-title-gsm-2 ccco-qty-number">01</div>
                  <button type="button" className="ccco-qty-minus w-inline-block">
                    <div className="ccco-qty-ico"></div>
                  </a>
                </div>
                <div className="cc-co-sp-pricewrap">
                  <div className="ccco-sp-price">$230/Yr</div>
                </div>
              </div>
              <div className="cc-co-summary-pwrap cc-co-bundle-summary">
                <img
                  src="images/mastercard_circles_92px_2x.png"
                  alt=""
                  className="ccco-sum-pro-img"
                />
                <div className="cc-co-sp-titlewrap">
                  <div className="cc-co-sp-title">Coot Analytics Premium</div>
                  <div className="cc-co-sp-desc">Subscription ID #234CA20</div>
                </div>
                <div className="cc-co-sum-qty">
                  <button type="button" className="ccco-qty-minus w-inline-block">
                    <div className="ccco-qty-ico"></div>
                  </a>
                  <div className="cc-title-gsm-2 ccco-qty-number">01</div>
                  <button type="button" className="ccco-qty-minus w-inline-block">
                    <div className="ccco-qty-ico"></div>
                  </a>
                </div>
                <div className="cc-co-sp-pricewrap">
                  <div className="ccco-sp-price">$230/Yr</div>
                </div>
              </div>
              <div className="cc-co-summary-pwrap cc-co-bundle-summary">
                <img
                  src="images/mastercard_circles_92px_2x.png"
                  alt=""
                  className="ccco-sum-pro-img"
                />
                <div className="cc-co-sp-titlewrap">
                  <div className="cc-co-sp-title">Coot Analytics Premium</div>
                  <div className="cc-co-sp-desc">Subscription ID #234CA20</div>
                </div>
                <div className="cc-co-sum-qty">
                  <button type="button" className="ccco-qty-minus w-inline-block">
                    <div className="ccco-qty-ico"></div>
                  </a>
                  <div className="cc-title-gsm-2 ccco-qty-number">01</div>
                  <button type="button" className="ccco-qty-minus w-inline-block">
                    <div className="ccco-qty-ico"></div>
                  </a>
                </div>
                <div className="cc-co-sp-pricewrap">
                  <div className="ccco-sp-price">$230/Yr</div>
                </div>
              </div>
              <div className="cc-co-summary-pwrap cc-co-bundle-summary">
                <img
                  src="images/mastercard_circles_92px_2x.png"
                  alt=""
                  className="ccco-sum-pro-img"
                />
                <div className="cc-co-sp-titlewrap">
                  <div className="cc-co-sp-title">Coot Analytics Premium</div>
                  <div className="cc-co-sp-desc">Subscription ID #234CA20</div>
                </div>
                <div className="cc-co-sum-qty">
                  <button type="button" className="ccco-qty-minus w-inline-block">
                    <div className="ccco-qty-ico"></div>
                  </a>
                  <div className="cc-title-gsm-2 ccco-qty-number">01</div>
                  <button type="button" className="ccco-qty-minus w-inline-block">
                    <div className="ccco-qty-ico"></div>
                  </a>
                </div>
                <div className="cc-co-sp-pricewrap">
                  <div className="ccco-sp-price">$230/Yr</div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ProductSummary;
