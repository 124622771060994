const dev = {
  billingAPIUrl: "http://127.0.0.1:3005",
  accountUrl: "http://127.0.0.1:3100"
};

const prod = {
  billingAPIUrl: "https://billing-api.coot.ai",
  accountUrl: "https://accounts.coot.ai"
};

export default prod;
