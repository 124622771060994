import React from "react";

const Coupon =({
  couponCode = "ccco-20off",
  couponDescription = "20% OFF on Purchasing 2 Year Plan",
  renderCouponBenifit,
  removeCoupon,
  id
}) =>{
  return (
    <div className="ccco-s-c-applied">
      <div className="ccco-sum-offer-pill ccco-w-auto ccco-pill-green">
        {couponCode}
      </div>
      <div className="ccco-sum-offer-desc ccco-s-c-desc">
        {couponDescription}
      </div>
      {renderCouponBenifit()}
      <button
        onClick={() => removeCoupon(id)}
        style={{ cursor: "pointer" }}
        className="ccco-sp-vd-link ccco-s-c-remove"
      >
        Remove
      </button>
    </div>
  );
}

export default Coupon;
