import React from "react";
import Product from "./Product";
import Offer from "./Offer";
import Skeleton from "react-loading-skeleton";

const ProductsContainer = ({ products }) => {
  return (
    <div className="ccco-sum-cartwrap">
      {products[0] ? (
        <>
          <Product {...products[0]} renderOffer={() => <Offer />} />
          <button type="button" className="ccc-sum-viewmore w-inline-block">
            <div className="ccco-sum-viewmorelink">View 2 More Product</div>
          </button>
        </>
      ) : (
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex"
            }}
          >
            <Skeleton width={100} height={100} />
            <div style={{ width: "100%", paddingLeft: "20px" }}>
              <Skeleton count={1} />
              <div style={{ width: "90%" }}>
                <Skeleton count={1} />
              </div>
              <div style={{ width: "70%" }}>
                <Skeleton count={1} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsContainer;
