import React from "react";

const ProductDetailsDialog = ({ open, closeDialog }) => {
  return (
    <div
      id="summary-product-detail"
      className={`ccco-p-detail-container ${
        open ? "ccco-p-detail-container-open" : ""
      }`}
    >
      <div className="ccco-p-details-ibber">
        <div className="ccco-sp-title-wrap">
          <div className="cc-co-title">Product Summary</div>
          <button
            style={{ cursor: "pointer" }}
            onClick={closeDialog}
            className="ccco-sp-detail-close w-inline-block"
          >
            <div className="cc-sp-close-ico ccco-sp-vd-link ccco-close-ico cc-btn-trigger">
              
            </div>
          </button>
        </div>
        <div className="ccoo-sp-details-inner">
          <div className="ccco-sp-detail-desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Consequat nisl vel pretium lectus. Dictum non consectetur a erat nam
            at lectus urna duis. Vitae elementum curabitur vitae nunc sed velit.
            Varius vel pharetra vel turpis.
          </div>
          <div className="ccco-sp-detail-desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Consequat nisl vel pretium lectus. Dictum non consectetur a erat nam
            at lectus urna duis. Vitae elementum curabitur vitae nunc sed velit.
            Varius vel pharetra vel turpis.
          </div>
          <div className="ccco-sp-detail-desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Consequat nisl vel pretium lectus. Dictum non consectetur a erat nam
            at lectus urna duis. Vitae elementum curabitur vitae nunc sed velit.
            Varius vel pharetra vel turpis.
          </div>
          <div className="ccco-sp-detail-desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Consequat nisl vel pretium lectus. Dictum non consectetur a erat nam
            at lectus urna duis. Vitae elementum curabitur vitae nunc sed velit.
            Varius vel pharetra vel turpis.
          </div>
        </div>
        <button type="button" className="cc-primary--cta ccco-mt20 w-button">
          Add Coot Designer to Cart
        </button>
      </div>
    </div>
  );
};

export default ProductDetailsDialog;
