import React from "react";
// import CarouselProduct from "./CarouselProduct";
// import ProductDetailsDialog from "../ProductDetailsDialog";
import Skeleton from "react-loading-skeleton";

const CarouselProducts = ({ showDialog }) => {
  return (
    <div className="ccco-sugesstionbox">
      <div className="ccco-sp-title-wrap">
        <div className="cc-co-sp-title cc-co-sp-title-small">
          Suggested Products{" "}
        </div>
        <button type="button" className="ccco-so-offer-btn w-inline-block">
          <div className="ccco-sp-offer-txt">View On-going Offers</div>
        </button>
      </div>
      <div className="ccco-product-sliderwrap">
        <div className="ccco-sucards-wrap">
          {/* {Array(3)
            .fill(1)
            .map((product, index) => (
              <CarouselProduct
                key={index}
                index={index}
                showDialog={showDialog}
              />
            ))} */}
          {Array(3)
            .fill(1)
            .map((item, i) => (
              <React.Fragment key={i}>
                <div className="ccco-su-pcard">
                  <div style={{ maxWidth: "100%" }}>
                    <Skeleton count={1} height={90} />
                  </div>
                  <div className="ccco-su-pcard--desc">
                    <div
                      style={{
                        maxWidth: "100%",
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <Skeleton width={370} height={20} />
                      <Skeleton width={370} height={20} />
                      <Skeleton width={370} height={20} />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselProducts;
