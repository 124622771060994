import React from "react";
import Footer from "../Footer";

const ProcessingPayment = () => {
  return (
    <div className="section">
      <div
        className="cc-checkout-container"
        style={{ width: "100vw", borderRadius: "0px", maxHeight: "100vh" }}
      >
        <div className="ccco-process-inner">
          <div className="ccco-process-inner-contianer">
            <div className="progress-ring-container">
              <div className="ccco-progress-circle">
                <div className="ccco-progress-ring" />
                <div className="ccco-progress-txt">Processing Payment..</div>
              </div>
            </div>
            <div className="ccco-process-title">
              Please wait.. Your Transacion is being processed and you will be
              redirected to the payment gateway website.
            </div>
            <div className="ccco-process-rlink">
              If you are not automatically redirected in to PaymentGateway
              within 5 seconds.{" "}
              <button type="button" className="ccco-redirectlink">
                Click here
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ProcessingPayment;
