import React, { useEffect } from "react";
import Translation from "../Language/Translation";

const TransactionFailed = () => {
  useEffect(() => {
    setInterval(() => {
      window.location.reload();
    }, 5000);
  }, []);

  return (
    <div className="section">
      <div
        className="cc-checkout-container"
        style={{ width: "100vw", maxHeight: "100vh" }}
      >
        <div className="ccco-process-inner ccco-process-inner-full">
          <div className="ccco-process-inner-contianer">
            <div className="ccco-progress-pendingico">
              <div className="ccco-pp-ico"></div>
            </div>
            <div className="ccco-process-title">
              {Translation.t(
                "TRANSACTION_DECLINED",
                "Transaction declined due to technical issue at payment gateway"
              )}
              .
            </div>
            <div className="ccco-process-rlink">
              {Translation.t(
                "REDIRECT_AUTOMATICALLY",
                "If you are not automatically redirected in to PaymentGateway within 5 seconds"
              )}
              .{" "}
              <button
                type="button"
                className="ccco-redirectlink"
                onClick={() => window.location.reload()}
              >
                {Translation.t("CLICK_HERE", "Click here")}
              </button>
            </div>
            <button
              type="button"
              className="cc-primary--cta ccco-mt20 ccoo-cta-retry w-button"
              onClick={() => window.location.reload()}
            >
              {Translation.t("RETRY_PAYMENT", "Retry Payment")}
            </button>
            <div className="ccco-process-rlink ccco-mt-10 open-drawer-checkout">
              <button
                type="button"
                onClick={() => window.location.reload()}
                data-drawer-id="ccco-checkout-mobile"
                className="ccco-redirectlink open-drawer-checkout"
              >
                {Translation.t(
                  "CANCEL_AND_BAK_TO_HOME",
                  "Cancel and Back to Home"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionFailed;
